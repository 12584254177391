import { Controller } from 'stimulus'; 
export default class extends Controller {

  copyPasswordToClipboard(){
    let password = event.target.dataset.password;
    // クリップボードにコピーする
    navigator.clipboard.writeText(password);

    if (document.getElementById('successMessage') == null) {
      // コピー完了メッセージを表示
      let successMessageElement = '<span id="successMessage" class="ml-3 font-weight-bold text-danger">コピーしました！</span>';
      event.srcElement.parentNode.insertAdjacentHTML('beforeend', successMessageElement); 
    }
  }
}