import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ['formRow','rowOutput', 'addButton', 'submitButton']

  // NOTE: このファイルからjavascript/images配下の画像の読み込みはできてないため、public/images配下のアイコン画像を読み込んでいます。

  connect() {
    // カテゴリが0件の場合、保存ボタンを削除して「カテゴリを追加するボタン」を設置
    if (this.formRowTargets.length == 0) {
      const addButton = `
      <a data-action="admin--category-form#createRow" class="btn bg-warning font-weight-bold mx-2">カテゴリを追加する</a>
      `

      let submitContainer = this.submitButtonTarget;
      submitContainer.innerHTML = '';
      submitContainer.insertAdjacentHTML('beforeend', addButton);
    }
  }

  addRow(event) {
    // クリックした「追加ボタン」の削除
    event.currentTarget.remove();

    // 行の追加
    const row = `
      <div class="d-flex" data-admin--category-form-target="formRow">
        <input name="new_categories[]" type="text" class="form-control mb-3 w-75" placeholder="カテゴリを追加してください">
        <a data-action="admin--category-form#removeRow"><img src="/images/remove_category_icon.png" class="ml-3"></a>
        <a data-action="admin--category-form#addRow" class="ml-3 add-category-button"><img src="/images/add_category_icon.png"></a>
      </div>
    `
    // REF: https://developer.mozilla.org/ja/docs/Web/API/Element/insertAdjacentHTML
    this.rowOutputTarget.insertAdjacentHTML('beforeend', row);
  }

  removeRow(event) {
    // 「削除ボタン」　が押された行を削除
    // REF: https://github.com/hotwired/stimulus/issues/158
    let row = event.currentTarget.parentNode;
    row.remove();

    // 行が０件　になった場合の処理
    // -> 「保存ボタン」　から　「カテゴリ追加ボタン」　に変更して早期リターン
    if (this.formRowTargets.length == 0) {
      const addButton = `
      <a data-action="admin--category-form#createRow" class="btn bg-warning font-weight-bold mx-2">カテゴリを追加する</a>
      <input type="submit" name="commit" value="保存する" class="btn bg-warning font-weight-bold mx-2" data-admin--category-form-target="submitButton" data-disable-with="保存する">
      `

      let submitContainer = this.submitButtonTarget;
      submitContainer.innerHTML = '';
      submitContainer.insertAdjacentHTML('beforeend', addButton);

      return;
    }

    // 追加ボタン付きの行　を削除した場合の処理
    // -> 最後の行に　「追加ボタン」　を設置
    if (row.querySelector('.add-category-button') != null) {
      const addButton = `
        <a data-action="admin--category-form#addRow" class="ml-3 add-category-button"><img src="/images/add_category_icon.png"></a>
      `

      let lastRow = this.formRowTargets.slice(-1)[0];
      lastRow.insertAdjacentHTML('beforeend', addButton);
    }
  }

  createRow() {
    // 行を作成
    const row = `
      <div class="d-flex" data-admin--category-form-target="formRow">
        <input name="new_categories[]" type="text" class="form-control mb-3 w-75" placeholder="カテゴリを追加してください">
        <a data-action="admin--category-form#removeRow"><img src="/images/remove_category_icon.png" class="ml-3"></a>
        <a data-action="admin--category-form#addRow" class="ml-3 add-category-button"><img src="/images/add_category_icon.png"></a>
      </div>
    `

    this.rowOutputTarget.insertAdjacentHTML('beforeend', row);    

    // 「カテゴリ作成ボタン」　を　「保存ボタン」　に変更
    const submitButton = `
      <input type="submit" name="commit" value="保存する" class="btn bg-warning font-weight-bold mx-2" data-admin--category-form-target="submitButton" data-disable-with="保存する">    
    `

    let submitContainer = this.submitButtonTarget;
    submitContainer.innerHTML = '';
    submitContainer.insertAdjacentHTML('beforeend', submitButton);
  }
}