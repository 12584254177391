import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ['email', 'password']

  connect() {
    let modalButton = document.getElementById('show-password-modal');
    // 自動生成パスワードが存在する場合はモーダルを開く。
    if (modalButton.value !== '') {
      modalButton.click();
      // モーダルを閉じて、他のページへ遷移した後にまた戻るとモーダルが表示されるので値を空にしておく。
      modalButton.value = '';
    }
  }

  copyToClipboard(){
    // コピー内容を生成
    let content = '■ログイン情報\n' + this.emailTarget.textContent + '\n' + this.passwordTarget.textContent;
    // クリップボードにコピーする
    navigator.clipboard.writeText(content);
    
    if (document.getElementById('successMessage') == null) {
      // コピー完了メッセージを表示
      let successMessageElement = '<span id="successMessage" class="ml-3 font-weight-bold text-danger">コピーしました！</span>';
      event.srcElement.parentNode.insertAdjacentHTML('beforeend', successMessageElement); 
    }
  }

  // 閉じるボタンに確認ダイアログを表示して、モーダルをフェードアウトさせる
  fadeOutModalAfterConfirm() {
    // 確認ダイアログ
    let result = window.confirm('本当に閉じてもよろしいですか？');

    // 「OK」の場合: 非表示にしているボタンをクリックしてモーダルをフェードアウトさせる
    if (result) {
      let modalButton = document.getElementById('hide-password-modal');
      modalButton.click();
    }
  }
}