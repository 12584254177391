import { Controller } from 'stimulus'; 
export default class extends Controller {
  connect() {
    // ファイル添付を無効にする
    // REF: https://stackoverflow.com/questions/61880076/is-there-any-way-to-disable-image-attachment-part-for-action-text-in-rails-6    
    // window.addEventListener("trix-file-accept", function(event) {
    //   event.preventDefault()
    //   alert("ファイル添付は無効です。")
    // })

    // 添付ファイルにフィルターをかける
    // REF: https://blog.saeloun.com/2019/11/12/attachments-in-action-text-rails-6.html
    window.addEventListener("trix-file-accept", function(event) {
      // ファイルの種類を指定
      const acceptedTypes = ['image/jpeg', 'image/png']
      if (!acceptedTypes.includes(event.file.type)) {
        event.preventDefault()
        alert("添付できるファイルはJPEGとPNGのみです。")
      }

      // ファイルサイズを指定
      const maxFileSize = 1024 * 1024 // 1MB
      if (event.file.size > maxFileSize) {
        event.preventDefault()
        alert("1MBを超えているためアップロードできません。")
      }

    })

  }
}